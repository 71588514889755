




















































































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';

import LoadingSpinner from './LoadingSpinner.vue';

const otpTitle = `\
Due to limited translation capabilities, Hindi Braille has been limited to select NGOs.
English Braille is available for everyone regardless if you have an account or not.
If you want Hindi Braille, contact us at mail.drishtifoundation@gmail.com.`;

interface CData {
  showLogin: boolean;
  username: string;
  password: string;
  otp: string;
  showPassword: boolean;
  otpTitle: string;
}

interface CMethods {
  login: (credentials?: Credentials) => Promise<boolean>;
  signup: (payload: SignUpPayload) => Promise<boolean>;
  handleSubmit: () => void;
}

interface CComputed {
  pageName: string;
  isDisabled: boolean;
  isLoading: boolean;
}

interface CProps {
  show: boolean;
}

export default Vue.extend<CData, CMethods, CComputed, CProps>({
  name: 'login-signup',
  data: () => ({
    showLogin: true,
    username: '',
    password: '',
    otp: '',
    showPassword: false,
    otpTitle,
  }),
  methods: {
    ...mapActions(['login', 'signup']),
    async handleSubmit() {
      const credentials = { username: this.username.trim(), password: this.password.trim() };
      let success = false;
      if (this.showLogin) {
        success = await this.login(credentials);
      } else {
        success = await this.signup({ credentials, otp: this.otp.trim() });
      }
      if (success) {
        this.$emit('close');
      }
    },
  },
  computed: {
    ...mapState(['isLoading']),
    pageName() {
      return this.showLogin ? 'Login' : 'Sign Up';
    },
    isDisabled() {
      const userDataValid = (this.username.trim().length && this.password.trim().length) === 0;
      if (this.showLogin) {
        return userDataValid;
      }

      return userDataValid || this.otp.trim().length === 0;
    },
  },
  props: {
    show: Boolean,
  },
  watch: {
    show(show) {
      if (!show) {
        this.username = '';
        this.password = '';
        this.otp = '';
        this.showPassword = false;
      }
    },
  },
  components: {
    LoadingSpinner,
  },
});
