
































import Vue from 'vue';

interface CComputed {
  imgAlt: string;
}

interface CProps {
  imgUrl: string;
  name: string;
  role: string;
  githubUrl: string;
  linkedinUrl: string;
}

export default Vue.extend<{}, {}, CComputed, CProps>({
  name: 'profile-card',
  props: {
    imgUrl: String,
    name: String,
    role: String,
    githubUrl: String,
    linkedinUrl: String,
  },
  computed: {
    imgAlt() {
      return `${this.name}, ${this.role} of Drishti`;
    },
  },
});
