




















import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

interface CMethods {
  logoutAndClose: () => void;
  logout: () => Promise<boolean>;
}

interface CComputed {
  username: string;
  isAdmin: boolean;
}

interface CProps {
  show: boolean;
}

export default Vue.extend<{}, CMethods, CComputed, CProps>({
  name: 'account-details',
  methods: {
    ...mapActions(['logout']),
    async logoutAndClose() {
      if (await this.logout()) {
        this.$emit('close');
      }
    },
  },
  computed: {
    ...mapState(['username']),
    isAdmin() {
      return this.username === 'admin';
    },
  },
  props: {
    show: Boolean,
  },
});
